import React from "react";
import { NoteSummay } from "../type/supabase";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "./Tooltip";
import { FaTrash } from "react-icons/fa";

interface NoteSummayProps {
  item: NoteSummay;
  deleteNote: (target: string) => void;
}

export function NoteSummary(props: NoteSummayProps) {
  const { title, content, id, created_at, updated_at } = props.item;
  const { deleteNote } = props;
  const navigate = useNavigate();

  const viewNote = async (id: string) => {
    const queryParams = new URLSearchParams({
      id: id.toString(),
    }).toString();
    return navigate(`/note/view?${queryParams} `);
  };

  return (
    <div
      className="bg-white p-4 rounded-lg shadow-md hover:bg-gray-300 cursor-pointer"
      onClick={() => viewNote(id)}
    >
      <div className="flex justify-between items-start">
        <h3 className="text-lg font-semibold overflow-hidden text-ellipsis line-clamp-1">
          {title}
        </h3>
        <Tooltip tooltipText="削除">
          <button
            className="w-10 h-10 bg-red-500 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-red-600"
            onClick={(e) => {
              e.stopPropagation(); // ノートのクリックイベントを防ぐ
              deleteNote(id);
            }}
          >
            <FaTrash />
          </button>
        </Tooltip>
      </div>
      <div className="text-gray-700 overflow-hidden text-ellipsis line-clamp-2">
        {content}
      </div>
      <div className="mt-2 text-gray-500 text-sm">
        <div className="font-bold">
          作成日:{" "}
          {`${created_at.toLocaleDateString()} ${created_at.toLocaleTimeString()}`}
        </div>
        <div className="font-bold">
          更新日:{" "}
          {`${updated_at.toLocaleDateString()} ${updated_at.toLocaleTimeString()}`}
        </div>
      </div>
    </div>
  );
}
