import { supabase } from "../lib/supabase";
import { Note, SimpleNote } from "../type/supabase";
import { isNote, isNoteSummaries } from "../util/typeCheck";

export const NoteRepository = {
  async create(
    title: string,
    content: string,
    userId: string,
    id: string | undefined
  ): Promise<Note> {
    const { data, error } = await supabase
      .from("note")
      .upsert(
        id
          ? { id, user_id: userId, title, content }
          : { user_id: userId, title, content }
      )
      .select();
    if (error) throw new Error(error.message);

    if (data && Array.isArray(data) && data.length > 0 && isNote(data[0])) {
      return data[0];
    }

    throw new Error("Failed to create Note entry");
  },

  async get(id: string, userId: string): Promise<Note> {
    const { data, error } = await supabase
      .from("note")
      .select("*")
      .eq("user_id", userId)
      .eq("id", id);
    if (error) throw new Error(error.message);

    if (data && Array.isArray(data) && data.length > 0 && isNote(data[0])) {
      return {
        ...data[0],
        created_at: new Date(data[0].created_at),
        updated_at: new Date(data[0].updated_at),
      };
    }

    throw new Error("Failed to get Note entry");
  },

  async find(page: number, limit: number, userId: string) {
    page = isNaN(page) || page < 1 ? 1 : page;
    const start = (page - 1) * limit;
    const end = start + limit - 1;
    const { data, error } = await supabase
      .rpc("get_note_previews")
      .range(start, end)
      .eq("user_id", userId)
      .order("created_at", { ascending: false });
    if (error) throw new Error(error.message);
    if (!data || data.length === 0) {
      return [];
    }

    if (data && data.length > 0 && isNoteSummaries(data)) {
      return data.map((item) => {
        return {
          id: item.id,
          title: item.title,
          content: item.content,
          created_at: new Date(item.created_at),
          updated_at: new Date(item.updated_at),
        };
      });
    }

    throw new Error("Failed to find Note");
  },

  async findTitles(userId: string): Promise<SimpleNote[]> {
    const { data, error } = await supabase
      .from("note")
      .select("id, title")
      .eq("user_id", userId)
      .order("created_at", { ascending: false });
    if (error) throw new Error(error.message);
    if (!data || data.length === 0) {
      return [];
    }

    if (data && data.length > 0) {
      return data.map((item) => {
        return {
          id: item.id,
          title: item.title,
        };
      });
    }

    throw new Error("Failed to find Note");
  },

  async delete(id: string, user_id: string) {
    const { error } = await supabase
      .from("note")
      .delete()
      .eq("id", id)
      .eq("user_id", user_id);
    if (error) throw new Error(error.message);
    return true;
  },
};
