import { Bounce, toast, ToastContent } from "react-toastify";

export function promiseToast<T>(
  promise: Promise<T>,
  pending: string,
  success: string,
  error: string
) {
  return toast.promise(
    promise,
    {
      pending,
      success,
      error,
    },
    {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      transition: Bounce,
    }
  );
}

export function successToast(error: string) {
  return toast.success(error, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  });
}

export function errorToast(error: string) {
  return toast.error(error, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    transition: Bounce,
  });
}

export function warnToast(content: ToastContent<unknown>) {
  toast.warn(content, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: false,
    closeButton: false,
    draggable: false,
    pauseOnHover: true,
  });
}
