import React, { useEffect, useState, useCallback, useContext } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { NoteRepository } from "../repositories/note";
import { SessionContext } from "../SessionProvider";
import { Note } from "../type/supabase";
import HighLighterMarkDown from "../components/HighLighterMarkDown";
import MainLayout from "../components/MainLayout";
import { FaPenAlt } from "react-icons/fa";
import { Tooltip } from "../components/Tooltip";

const ViewNote = () => {
  const [note, setNote] = useState<Note>();
  const { currentUserId } = useContext(SessionContext);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const getNote = useCallback(async () => {
    const result = await NoteRepository.get(id!, currentUserId);
    setNote(result);
  }, [currentUserId, id]);
  const navigate = useNavigate();
  const editNote = () => {
    const queryParams = new URLSearchParams({
      id: id!,
    }).toString();
    return navigate(`/note/edit?${queryParams} `);
  };
  useEffect(() => {
    if (id) {
      getNote();
    }
  }, [id, getNote]);

  if (!currentUserId) {
    return <Navigate replace to={"/signin"} />;
  }

  if (!id) {
    return <Navigate replace to={"/"} />;
  }

  return (
    <MainLayout>
      {note && (
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-4 justify-between items-center">
            <h2 className="font-bold text-2xl mb-2 w-full">{note.title}</h2>
            <button
              className="min-w-10 min-h-10 max-h-10 bg-blue-500 rounded-full flex lg:hidden items-center justify-center text-white shadow-lg hover:bg-blue-600"
              onClick={editNote}
            >
              <FaPenAlt />
            </button>
          </div>
          <div className="flex lg:flex-row flex-col gap-4 items-start w-full">
            <div className="px-4 border-2 bg-white rounded-lg w-full lg:min-w-[80vh] overflow-auto">
              <HighLighterMarkDown content={note.content} />
            </div>
            <div className="flex flex-col">
              <Tooltip tooltipText="編集">
                <button
                  className="w-14 h-14 bg-blue-500 rounded-full lg:flex hidden items-center justify-center text-white shadow-lg hover:bg-blue-600"
                  onClick={editNote}
                >
                  <FaPenAlt />
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default ViewNote;
