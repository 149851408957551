import { useContext, useState, useEffect, useRef } from "react";
import { authRepository } from "../repositories/auth";
import { SessionContext } from "../SessionProvider";
import { Link } from "react-router-dom"; // useHistoryを追加
import {
  FaPollH,
  FaPen,
  FaHome,
  FaSignOutAlt,
  FaKey,
  FaDoorOpen,
  FaEdit,
} from "react-icons/fa";
import { CiMenuBurger } from "react-icons/ci";
import React from "react";

interface HeaderProps {
  setIsMenuOpen: (value: boolean) => void;
}

const Header = (props: HeaderProps) => {
  const { setIsMenuOpen } = props;
  const headerRef = useRef<HTMLDivElement>(null);

  return (
    <div
      ref={headerRef}
      className="flex flex-row w-full justify-between bg-gray-700 sticky p-1"
    >
      <div
        className="text-white w-10 h-10 flex items-center justify-center cursor-pointer"
        onClick={() => setIsMenuOpen(true)}
      >
        <CiMenuBurger />
      </div>
      <Link
        className="text-white w-10 h-10 flex items-center justify-center"
        to="/"
      >
        <FaHome />
      </Link>
      <Link
        className="text-white w-10 h-10 flex items-center justify-center"
        to="/note/create"
      >
        <FaEdit />
      </Link>
    </div>
  );
};

export default Header;
