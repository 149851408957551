import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import SideMenu from "./SideMenu";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import { Tooltip } from "./Tooltip";
import { NoteRepository } from "../repositories/note";
import { SessionContext } from "../SessionProvider";

const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const { currentUserId } = useContext(SessionContext);
  const navigate = useNavigate(); // フックを宣言

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // メニューが開いているときのみ、メニュー外をクリックした場合にメニューを閉じる
      if (
        isMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(event.target as Node)
      ) {
        setIsMenuOpen(false);
      }
    };

    // ドキュメントに対してクリックイベントリスナーを追加
    document.addEventListener("mousedown", handleClickOutside);

    // クリーンアップ関数でイベントリスナーを削除
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isMenuOpen]);

  const goBack = () => {
    navigate(-1); // ブラウザの履歴を一つ戻る
  };
  return (
    <div className="bg-gray-100">
      <div className="container flex lg:flex-row flex-col w-full max-w-full h-dvh">
        {/* モバイルサイズでのみ表示されるヘッダー */}
        <div className="static w-full lg:hidden z-10">
          <Header setIsMenuOpen={setIsMenuOpen} />
        </div>

        {/* メニューの表示・非表示を制御 */}
        <div
          ref={menuRef}
          className={`${
            isMenuOpen ? "block" : "hidden"
          } lg:block fixed lg:static z-20`}
        >
          <SideMenu setIsMenuOpen={isMenuOpen ? setIsMenuOpen : undefined} />
        </div>
        <div className="absolute left-48 top-16 hidden lg:block">
          <Tooltip tooltipText="戻る">
            <button
              className="w-10 h-10 bg-gray-500 rounded-full lg:flex hidden items-center justify-center text-white shadow-lg hover:bg-gray-600"
              onClick={goBack}
            >
              <FaArrowLeft />
            </button>
          </Tooltip>
        </div>
        <div className="py-4 lg:px-20 px-4 h-dvh mx-auto w-full overflow-x-hidden">
          {children}
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
