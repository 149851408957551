import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { authRepository } from "./repositories/auth";
import React from "react";
import { NoteRepository } from "./repositories/note";
import { SimpleNote } from "./type/supabase";

interface SessionContextType {
  currentUserId: string;
  setCurrentUserId: (userId: string) => void;
  notes: SimpleNote[];
  setNotes: (notes: SimpleNote[]) => void;
}

const SessionContext = createContext<SessionContextType>({
  currentUserId: "",
  setCurrentUserId: () => {},
  notes: [],
  setNotes: () => {},
});

interface SessionProviderProps {
  children: ReactNode;
}

const SessionProvider: React.FC<SessionProviderProps> = (props) => {
  const [currentUserId, setCurrentUserId] = useState<string>("");
  const [isLoading, setIsLoading] = useState(true);
  const [notes, setNotes] = useState<SimpleNote[]>([]);

  const setSession = async () => {
    const currentUser = await authRepository.getCurrentUser();
    setCurrentUserId(currentUser);
    setIsLoading(false);
  };

  useEffect(() => {
    setSession();
  }, []);

  const getNotes = useCallback(async () => {
    try {
      const result = await NoteRepository.findTitles(currentUserId);
      setNotes(result);
    } catch (error) {
      // 動作に影響ないため無視
    }
  }, [currentUserId]);

  useEffect(() => {
    if (currentUserId) {
      getNotes();
    }
  }, [currentUserId, getNotes]);

  if (isLoading) return <div />;

  return (
    <SessionContext.Provider
      value={{ currentUserId, setCurrentUserId, notes, setNotes }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };
