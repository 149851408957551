import { Note } from "../type/supabase";
import React, { useContext, useEffect, useRef, useState } from "react";
import { NoteRepository } from "../repositories/note";
import { SessionContext } from "../SessionProvider";
import { useNavigate } from "react-router-dom";
import { errorToast, promiseToast } from "../util/toast";
import HighLighterMarkDown from "./HighLighterMarkDown";
import { Tooltip } from "./Tooltip";
import { FaRobot, FaSave } from "react-icons/fa";
import AIReviewPanel from "./AIReviewPanel";
import AIReviewBody from "./AIReviewBody";

interface InputNoteProps {
  note: Note | undefined;
}

export function InputNote(props: InputNoteProps) {
  const { note } = props;
  const { currentUserId, notes, setNotes } = useContext(SessionContext);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [content, setContent] = useState("");
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const [title, setTitle] = useState("");
  const [activeTab, setActiveTab] = useState("input");
  const navigate = useNavigate();

  const postNote = async () => {
    try {
      if (title.trim().length > 100) {
        errorToast("タイトルは100文字以内で入力してください");
        return;
      }

      if (content.trim().length > 5000) {
        errorToast("本文は5000文字以内で入力してください");
        return;
      }

      const result = await promiseToast<Note>(
        NoteRepository.create(
          title.trim(),
          content.trim(),
          currentUserId,
          note?.id
        ),
        "保存しています...",
        "保存しました！",
        "保存に失敗しました！"
      );

      notes.unshift({ id: result.id, title: result.title });
      setNotes(notes);

      const queryParams = new URLSearchParams({
        id: result.id.toString(),
      }).toString();
      return navigate(`/note/view?${queryParams} `);
    } catch (error) {
      console.error("Failed to post Note:", error);
    }
  };

  const handleScroll = () => {
    const textArea = inputRef.current;
    const preview = previewRef.current;
    if (textArea && preview) {
      const ratio =
        textArea.scrollTop / (textArea.scrollHeight - textArea.clientHeight);
      preview.scrollTop = ratio * (preview.scrollHeight - preview.clientHeight);
    }
  };

  const isInput = title.trim() && content.trim();

  useEffect(() => {
    if (note) {
      setTitle(note.title);
      setContent(note.content);
    }
  }, [note]);

  return (
    <div className="flex flex-row lg:flex-row gap-4 items-start h-full">
      <div className="flex flex-col lg:flex-row gap-4 items-start w-full h-full">
        <div className="flex flex-col lg:gap-4 w-full h-full">
          <div className="flex flex-row justify-between gap-4 w-full">
            <input
              className="lg:p-4 p-2 border-2 rounded-lg focus:outline-none w-full"
              value={title}
              placeholder="タイトル"
              onChange={(e) => setTitle(e.target.value)}
            />

            <button
              className={`w-10 h-10 bg-blue-500 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed lg:hidden`}
              disabled={!content.trim() || !title.trim()}
              aria-disabled={!content.trim() || !title.trim()}
              onClick={() => isInput && postNote()}
            >
              <FaSave className="w-10" />
            </button>
          </div>
          <div className="flex lg:flex-row flex-col gap-4 h-full overflow-y-hidden">
            <div className="lg:hidden flex flex-col gap-4 h-full">
              <div className="flex justify-around border-b">
                <button
                  className={`p-4 ${
                    activeTab === "input" ? "border-b-2 border-blue-500" : ""
                  }`}
                  onClick={() => setActiveTab("input")}
                >
                  入力
                </button>
                <button
                  className={`p-4 ${
                    activeTab === "preview" ? "border-b-2 border-blue-500" : ""
                  }`}
                  onClick={() => setActiveTab("preview")}
                >
                  プレビュー
                </button>
                <button
                  className={`p-4 ${
                    activeTab === "aiReview" ? "border-b-2 border-blue-500" : ""
                  }`}
                  onClick={() => setActiveTab("aiReview")}
                >
                  AIレビュー
                </button>
              </div>
              {activeTab === "input" && (
                <textarea
                  id="auto-resize-textarea"
                  className="lg:p-4 p-2  border-2 rounded-lg focus:outline-none w-full resize-none flex-grow h-full"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  onScroll={handleScroll}
                  placeholder="本文(マークダウン)"
                />
              )}
              {activeTab === "preview" && (
                <div
                  ref={previewRef}
                  className="px-4 bg-white rounded-lg w-full overflow-auto h-full flex-grow"
                >
                  <HighLighterMarkDown content={content} />
                </div>
              )}
              {activeTab === "aiReview" && (
                <AIReviewBody
                  title={title}
                  content={content}
                  handleClosePanel={() => setIsPanelOpen(false)}
                />
              )}
            </div>

            <div className="hidden lg:flex lg:flex-row gap-4 w-full">
              <textarea
                ref={inputRef}
                className="lg:p-4 p-2  border-2 rounded-lg focus:outline-none w-full resize-none flex-grow"
                value={content}
                onChange={(e) => setContent(e.target.value)}
                onScroll={handleScroll}
                placeholder="本文(マークダウン)"
              />
              <div
                ref={previewRef}
                className="px-4 bg-white rounded-lg w-full overflow-auto flex-grow"
              >
                <HighLighterMarkDown content={content} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lg:flex lg:flex-col flex-row gap-4 mt-20 hidden">
        <Tooltip tooltipText="AIレビュー">
          <button
            className={`w-14 h-14 bg-blue-500 rounded-full lg:flex hidden items-center justify-center text-white shadow-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed`}
            disabled={!content.trim() || !title.trim()}
            aria-disabled={!content.trim() || !title.trim()}
            onClick={() => isInput && setIsPanelOpen(true)}
          >
            <FaRobot className="w-10" />
          </button>
        </Tooltip>
        <Tooltip tooltipText="保存">
          <button
            className={`w-14 h-14 bg-blue-500 rounded-full flex items-center justify-center text-white shadow-lg hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed`}
            disabled={!content.trim() || !title.trim()}
            aria-disabled={!content.trim() || !title.trim()}
            onClick={() => isInput && postNote()}
          >
            <FaSave className="w-10" />
          </button>
        </Tooltip>
      </div>
      {isPanelOpen && (
        <AIReviewPanel
          title={title}
          content={content}
          handleClosePanel={() => setIsPanelOpen(false)}
        />
      )}
    </div>
  );
}
