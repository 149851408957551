import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { SessionContext } from "../SessionProvider";
import { InputNote } from "../components/InputNote";
import MainLayout from "../components/MainLayout";

const CreateNote = () => {
  const { currentUserId } = useContext(SessionContext);
  if (!currentUserId) {
    return <Navigate replace to={"/signin"} />;
  }
  return (
    <MainLayout>
      <InputNote note={undefined} />
    </MainLayout>
  );
};

export default CreateNote;
