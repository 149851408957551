import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import React from "react";
import EditNote from "./pages/EditNote";
import ViewNote from "./pages/ViewNote";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CreateNote from "./pages/CreateNote";

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <div className="App">
        <div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<Signin />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/note/create" element={<CreateNote />} />
            <Route path="/note/edit" element={<EditNote />} />
            <Route path="/note/view" element={<ViewNote />} />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
