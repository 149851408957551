import { useContext } from "react";
import { authRepository } from "../repositories/auth";
import { SessionContext } from "../SessionProvider";
import { Link } from "react-router-dom";
import {
  FaHome,
  FaSignOutAlt,
  FaKey,
  FaDoorOpen,
  FaEdit,
  FaTimes,
} from "react-icons/fa";
import React from "react";

interface HeaderProps {
  setIsMenuOpen?: (value: boolean) => void;
}

const SideMenu = (props: HeaderProps) => {
  const { setIsMenuOpen } = props;
  const { setCurrentUserId, notes } = useContext(SessionContext);

  const signOut = async () => {
    await authRepository.signOut();
    setCurrentUserId("");
  };
  const linkStyle =
    "text-white text-sm font-bold flex flex-row items-center hover:bg-gray-700 py-2 px-4 rounded w-full";

  return (
    <nav className="bg-gray-900 h-dvh w-44 flex flex-col items-start justify-between py-4 lg:sticky min-w-40">
      <div className="flex flex-col w-full">
        <div
          className={`${linkStyle} lg:hidden cursor-pointer`}
          onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
        >
          <FaTimes className="text-green-400" />
          {setIsMenuOpen && <span className="ml-4 text-white">閉じる</span>}
        </div>
        <Link className={linkStyle + " hidden lg:flex"} to="/">
          <FaHome />
          <span className="ml-4">Home</span>
        </Link>
        <Link className={linkStyle + " hidden lg:flex"} to="/note/create">
          <FaEdit />
          <span className="ml-4">ノートを書く</span>
        </Link>
      </div>
      <div className="flex flex-col text-white h-full overflow-y-auto py-2 bg-gray-800">
        {notes.map((m) => {
          const queryParams = new URLSearchParams({
            id: m.id,
          }).toString();
          return (
            <Link
              className={linkStyle}
              to={`/note/view?${queryParams}`}
              onClick={() => setIsMenuOpen && setIsMenuOpen(false)}
            >
              <span className="ml-4 overflow-hidden text-ellipsis line-clamp-1">
                {m.title}
              </span>
            </Link>
          );
        })}
      </div>

      <div className="flex flex-col w-full">
        <div className={`${linkStyle} cursor-pointer`} onClick={signOut}>
          <FaSignOutAlt />
          <span className="ml-4">ログアウト</span>
        </div>
      </div>
    </nav>
  );
};

export default SideMenu;
