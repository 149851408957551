import React, { useState } from "react";
import Draggable from "react-draggable";
import { FaTimes } from "react-icons/fa";
import AIReviewBody from "./AIReviewBody";

interface AIReviewProps {
  title: string;
  content: string;
  handleClosePanel: () => void;
}

const AIReviewPanel = (props: AIReviewProps) => {
  const { title, content, handleClosePanel } = props;

  return (
    <Draggable handle=".drag-handle">
      <div className="fixed right-32 transform -translate-y-1/2 lg:w-2/5 lg:h-4/5 bg-gray-200 shadow-lg border-2 border-black rounded-lg">
        <div className="flex justify-end items-center drag-handle cursor-move bg-gray-700 border-b border-black stickey w-full">
          <button
            onClick={handleClosePanel}
            className="text-black w-8 h-8 flex items-center justify-center rounded-full border border-gray-400 bg-white"
          >
            <FaTimes />
          </button>
        </div>
        <AIReviewBody
          title={title}
          content={content}
          handleClosePanel={handleClosePanel}
        />
      </div>
    </Draggable>
  );
};

export default AIReviewPanel;
