import { supabase } from "../lib/supabase";

export const authRepository = {
  async signup(email: string, password: string): Promise<string> {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
    });
    if (error || !data?.user) {
      throw new Error(error?.message || "signup失敗");
    }

    return data.user.id;
  },

  async signin(email: string, password: string): Promise<string> {
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      throw new Error(error.message);
    }
    return data.user.id;
  },

  async signOut() {
    const { error } = await supabase.auth.signOut();
    if (error) throw new Error(error.message);

    return true;
  },

  async getCurrentUser(): Promise<string> {
    const { data, error } = await supabase.auth.getSession();
    if (error) throw new Error(error.message);
    if (!data.session) return "";

    return data.session.user.id;
  },
};
