import React, { useEffect, useState, useCallback, useContext } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { NoteRepository } from "../repositories/note";
import { SessionContext } from "../SessionProvider";
import { Note } from "../type/supabase";
import { InputNote } from "../components/InputNote";
import MainLayout from "../components/MainLayout";

const EditNote = () => {
  const [note, setNote] = useState<Note>();
  const { currentUserId } = useContext(SessionContext);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const getNote = useCallback(async () => {
    if (!id) return;
    const result = await NoteRepository.get(id, currentUserId);
    setNote(result);
  }, [currentUserId, id]);

  useEffect(() => {
    if (id) {
      getNote();
    }
  }, [id, getNote]);

  if (!currentUserId) {
    return <Navigate replace to={"/signin"} />;
  }

  if (!id) {
    return <Navigate replace to={"/"} />;
  }

  return (
    <MainLayout>
      <InputNote note={note} />
    </MainLayout>
  );
};

export default EditNote;
