import { useCallback, useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { NoteRepository } from "../repositories/note";
import { NoteSummary } from "../components/NoteSummary";
import { Pagination } from "../components/Pagination";
import React from "react";
import { SessionContext } from "../SessionProvider";
import { NoteSummay } from "../type/supabase";
import MainLayout from "../components/MainLayout";
import { successToast, warnToast } from "../util/toast";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";

const limit = 10;

function Home() {
  const {
    currentUserId,
    notes: allNotes,
    setNotes: setAllNotes,
  } = useContext(SessionContext);
  const [note, setNote] = useState<NoteSummay[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const moveNext = () => {
    console.log(page);
    setPage(page + 1);
  };

  const movePrev = () => {
    setPage(page - 1);
  };

  const getNotes = useCallback(async () => {
    if (!currentUserId) {
      return;
    }
    const result = await NoteRepository.find(page, limit, currentUserId);
    setNote(result);
    setIsLoading(false);
  }, [page, currentUserId]);

  const deleteNote = (target: string) => {
    const deleteExec = async () => {
      await NoteRepository.delete(target, currentUserId);
      toast.dismiss();
      const tmpNote = note.filter((f) => f.id !== target);
      setNote(tmpNote);

      // サイドメニューのノートの設定
      const newNotes = allNotes.filter((f) => f.id !== target);
      setAllNotes(newNotes);
      successToast("削除しました");
    };

    const content = (
      <div className="flex flex-col space-y-4">
        <p className="text-lg font-semibold">削除しますか?</p>
        <div className="flex justify-end space-x-4">
          <button
            onClick={() => toast.dismiss()}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition"
          >
            キャンセル
          </button>
          <button
            onClick={deleteExec}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
          >
            OK
          </button>
        </div>
      </div>
    );

    warnToast(content);
  };

  useEffect(() => {
    getNotes();
  }, [getNotes]);

  if (!currentUserId) {
    return <Navigate replace to={"/signin"} />;
  }

  return (
    <MainLayout>
      <div className="overflow-y-auto">
        <h2 className="font-bold text-3xl">ノート一覧</h2>
        <div className="flex flex-col gap-4 mt-4">
          {!isLoading ? (
            note.length > 0 ? (
              note.map((item) => {
                return (
                  <NoteSummary
                    key={item.id}
                    item={item}
                    deleteNote={deleteNote}
                  />
                );
              })
            ) : (
              <span>ノートはありません</span>
            )
          ) : (
            <div className="flex justify-center items-center h-64">
              <FaSpinner className="text-6xl animate-spin" />
            </div>
          )}
          {note.length > 0 && (
            <Pagination
              onPrev={page > 1 ? movePrev : null}
              onNext={
                note.length >= limit && limit * page < allNotes.length
                  ? moveNext
                  : null
              }
            />
          )}
        </div>
      </div>
    </MainLayout>
  );
}

export default Home;
