import React from "react";
import { memo, useRef } from "react";

// ツールチップ内に表示するためのprops
type Props = {
  tooltipText: string;
  children: React.ReactNode;
};

// ツールチップ
export const Tooltip: React.FC<Props> = memo((props) => {
  // ツールチップの文言自体のためのref
  const ref = useRef<HTMLDivElement>(null);

  // マウスが乗ったらツールチップを表示
  const handleMouseEnter = () => {
    if (!ref.current) return;
    ref.current.style.opacity = "1";
    ref.current.style.visibility = "visible";
  };
  // マウスが離れたらツールチップを非表示
  const handleMouseLeave = () => {
    if (!ref.current) return;
    ref.current.style.opacity = "0";
    ref.current.style.visibility = "hidden";
  };

  return (
    <div className="relative flex items-center">
      <div
        className="absolute left-1/2 bottom-full -translate-x-1/2 mb-2 p-2 text-xs text-white bg-gray-700 rounded opacity-0 invisible transition-opacity duration-200 z-10 whitespace-nowrap"
        ref={ref}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {props.tooltipText}
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2 w-2 h-2 bg-gray-700 rotate-45"></div>
      </div>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {props.children}
      </div>
    </div>
  );
});

Tooltip.displayName = "Tooltip";
