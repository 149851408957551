import React, { useState } from "react";
import { errorToast, promiseToast } from "../util/toast";
import HighLighterMarkDown from "./HighLighterMarkDown";
import openai from "../lib/openai";

const prompt = (points: string[], title: string, content: string) => `
今から渡されるノートの
${points
  .filter((f) => f !== "" && f != null)
  .map((point, index) => `\n- ${index + 1}: ${point}`)
  .join("")}
をそれぞれのレビュー観点に基づいて、別々でMarkdown形式かつ、タイトル部分を###で出力してください。
問題点の指摘や修正点の説明は、詳しく背景を説明してください。
======================================
タイトル：${title}
本文：
${content}
`;

interface AIReviewProps {
  title: string;
  content: string;
  handleClosePanel: () => void;
}

const AIReviewBody = (props: AIReviewProps) => {
  const { title, content } = props;
  const [reviewPoints, setReviewPoints] = useState(["", "", ""]);
  const [errors, setErrors] = useState<string[]>(["", "", ""]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [reviewResult, setReviewResult] = useState("");

  const isEmpty =
    reviewPoints.filter((point) => point === "" || point == null).length === 3;

  const handleInputChange = (index: number, value: string) => {
    const newReviewPoints = [...reviewPoints];
    newReviewPoints[index] = value;
    setReviewPoints(newReviewPoints);

    // バリデーション: 50文字を超えた場合エラーメッセージを設定
    const newErrors = [...errors];
    if (value.length > 50) {
      newErrors[index] = "50文字以内で入力してください";
    } else {
      newErrors[index] = "";
    }
    setErrors(newErrors);
  };

  const handleReviewSubmit = async () => {
    // 全てのフィールドがバリデーションをクリアしているかチェック
    const hasErrors = reviewPoints.some((point, index) => {
      return point.length > 50 || errors[index] !== "";
    });

    if (hasErrors) {
      return;
    }

    if (isEmpty) {
      errorToast("レビュー観点を入力してください");
    }

    setIsSubmitting(true);

    const result = await promiseToast<string>(
      openai.completion(prompt(reviewPoints, title, content)),
      "レビュー中...",
      "レビューしました",
      "レビューに失敗しました"
    );

    // mock
    // const result = reactHooksDescription;

    setReviewResult(result);
    setIsSubmit(true);
    setIsSubmitting(false);
  };

  return (
    <>
      <div className="lg:p-4 p-2 h-[95%] overflow-auto">
        <h2 className="text-xl font-bold mb-4">
          レビュー観点を入力してください
        </h2>

        {reviewPoints.map((point, index) => (
          <div key={index} className="mb-4">
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded"
              placeholder={`観点 ${index + 1}`}
              value={point}
              onChange={(e) => handleInputChange(index, e.target.value)}
            />
            {errors[index] && (
              <p className="text-red-500 text-sm mt-1">{errors[index]}</p>
            )}
          </div>
        ))}

        <button
          className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-700 disabled:bg-gray-400 disabled:cursor-not-allowed"
          onClick={handleReviewSubmit}
          disabled={
            errors.some((error) => error !== "") ||
            isEmpty ||
            isSubmitting ||
            isSubmit
          }
        >
          {isSubmitting
            ? "レビュー中..."
            : isSubmit
            ? "レビュー済"
            : "レビューを実行する"}
        </button>
        {reviewResult && isSubmit && !isSubmitting && (
          <>
            <h2 className="font-bold text-3xl my-4 ">レビュー結果</h2>
            <div className="px-4 border-2 bg-white rounded-lg w-full">
              <HighLighterMarkDown content={reviewResult} />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AIReviewBody;
