import { Note, NoteSummay } from "../type/supabase";

export function isNote(obj: any): obj is Note {
  return (
    obj &&
    typeof obj.id === "string" &&
    typeof obj.title === "string" &&
    typeof obj.content === "string"
  );
}

export function isNoteSummaries(obj: any): obj is NoteSummay[] {
  // 最初の要素のみチェック
  return (
    obj &&
    typeof obj[0].id === "string" &&
    typeof obj[0].title === "string" &&
    typeof obj[0].content === "string"
  );
}
